// Overrides user components-variables
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "../variables/_variables.scss";

// Google Color Palette defined: http://www.google.com/design/spec/style/color.html

$white: (
  "base": #ffffff
);
$black: (
  "base": #000000
);
$dark: (
  "base": theme-color("dark"),
  "gradient-bg": #640064
);
$light: (
  "base": theme-color("light"),
  "gradient-bg": #640064
);
$primary: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("primary")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("primary"), 25%),
  "lighten-5": lighten(theme-color("primary"), 25%),
  "lighten-4": lighten(theme-color("primary"), 20%),
  "lighten-3": lighten(theme-color("primary"), 15%),
  "lighten-2": lighten(theme-color("primary"), 10%),
  "lighten-1": lighten(theme-color("primary"), 5%),
  "base": theme-color("primary"),
  "darken-1": darken(theme-color("primary"), 5%),
  "darken-2": darken(theme-color("primary"), 10%),
  "darken-3": darken(theme-color("primary"), 15%),
  "darken-4": darken(theme-color("primary"), 20%),
  "accent-1": #bdfdff,
  "accent-2": #8afbff,
  "accent-3": #57faff,
  "accent-4": #3df9ff,
  "gradient-bg": #640064
);
$secondary: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("secondary")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("secondary"), 35%),
  "lighten-5": lighten(theme-color("secondary"), 25%),
  "lighten-4": lighten(theme-color("secondary"), 20%),
  "lighten-3": lighten(theme-color("secondary"), 15%),
  "lighten-2": lighten(theme-color("secondary"), 10%),
  "lighten-1": lighten(theme-color("secondary"), 5%),
  "base": theme-color("secondary"),
  "darken-1": darken(theme-color("secondary"), 5%),
  "darken-2": darken(theme-color("secondary"), 10%),
  "darken-3": darken(theme-color("secondary"), 15%),
  "darken-4": darken(theme-color("secondary"), 20%),
  "gradient-bg": #640064
);
$success: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("success")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("success"), 40%),
  "lighten-5": lighten(theme-color("success"), 25%),
  "lighten-4": lighten(theme-color("success"), 20%),
  "lighten-3": lighten(theme-color("success"), 15%),
  "lighten-2": lighten(theme-color("success"), 10%),
  "lighten-1": lighten(theme-color("success"), 5%),
  "base": theme-color("success"),
  "darken-1": darken(theme-color("success"), 5%),
  "darken-2": darken(theme-color("success"), 10%),
  "darken-3": darken(theme-color("success"), 15%),
  "darken-4": darken(theme-color("success"), 20%),
  "accent-1": #e1fff1,
  "accent-2": #aeffd9,
  "accent-3": #7bffc1,
  "accent-4": #62ffb5,
  "gradient-bg": #0064fa
);
$info: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("info")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("info"), 40%),
  "lighten-5": lighten(theme-color("info"), 25%),
  "lighten-4": lighten(theme-color("info"), 20%),
  "lighten-3": lighten(theme-color("info"), 15%),
  "lighten-2": lighten(theme-color("info"), 10%),
  "lighten-1": lighten(theme-color("info"), 5%),
  "base": theme-color("info"),
  "darken-1": darken(theme-color("info"), 5%),
  "darken-2": darken(theme-color("info"), 10%),
  "darken-3": darken(theme-color("info"), 15%),
  "darken-4": darken(theme-color("info"), 20%),
  "accent-1": #feffff,
  "accent-2": #cbf5ff,
  "accent-3": #98ecff,
  "accent-4": #7fe7ff,
  "gradient-bg": #640064
);
$warning: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("warning")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("warning"), 25%),
  "lighten-5": lighten(theme-color("warning"), 25%),
  "lighten-4": lighten(theme-color("warning"), 20%),
  "lighten-3": lighten(theme-color("warning"), 15%),
  "lighten-2": lighten(theme-color("warning"), 10%),
  "lighten-1": lighten(theme-color("warning"), 5%),
  "base": theme-color("warning"),
  "darken-1": darken(theme-color("warning"), 5%),
  "darken-2": darken(theme-color("warning"), 10%),
  "darken-3": darken(theme-color("warning"), 15%),
  "darken-4": darken(theme-color("warning"), 20%),
  "accent-1": #fff5ef,
  "accent-2": #ffe5d8,
  "accent-3": #fff6f3,
  "accent-4": #ffe3da,
  "gradient-bg": #fafafa
);
$danger: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("danger")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("danger"), 30%),
  "lighten-5": lighten(theme-color("danger"), 25%),
  "lighten-4": lighten(theme-color("danger"), 20%),
  "lighten-3": lighten(theme-color("danger"), 15%),
  "lighten-2": lighten(theme-color("danger"), 10%),
  "lighten-1": lighten(theme-color("danger"), 5%),
  "base": theme-color("danger"),
  "darken-1": darken(theme-color("danger"), 5%),
  "darken-2": darken(theme-color("danger"), 10%),
  "darken-3": darken(theme-color("danger"), 15%),
  "darken-4": darken(theme-color("danger"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #640064
);

$clean: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("clean")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("clean"), 30%),
  "lighten-5": lighten(theme-color("clean"), 25%),
  "lighten-4": lighten(theme-color("clean"), 20%),
  "lighten-3": lighten(theme-color("clean"), 15%),
  "lighten-2": lighten(theme-color("clean"), 10%),
  "lighten-1": lighten(theme-color("clean"), 5%),
  "base": theme-color("clean"),
  "darken-1": darken(theme-color("clean"), 5%),
  "darken-2": darken(theme-color("clean"), 10%),
  "darken-3": darken(theme-color("clean"), 15%),
  "darken-4": darken(theme-color("clean"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #A9B2E5
);

$clean-light: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("clean-light")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("clean-light"), 30%),
  "lighten-5": lighten(theme-color("clean-light"), 25%),
  "lighten-4": lighten(theme-color("clean-light"), 20%),
  "lighten-3": lighten(theme-color("clean-light"), 15%),
  "lighten-2": lighten(theme-color("clean-light"), 10%),
  "lighten-1": lighten(theme-color("clean-light"), 5%),
  "base": theme-color("clean-light"),
  "darken-1": darken(theme-color("clean-light"), 5%),
  "darken-2": darken(theme-color("clean-light"), 10%),
  "darken-3": darken(theme-color("clean-light"), 15%),
  "darken-4": darken(theme-color("clean-light"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #C2C6DC
);

$total: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("total")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("total"), 30%),
  "lighten-5": lighten(theme-color("total"), 25%),
  "lighten-4": lighten(theme-color("total"), 20%),
  "lighten-3": lighten(theme-color("total"), 15%),
  "lighten-2": lighten(theme-color("total"), 10%),
  "lighten-1": lighten(theme-color("total"), 5%),
  "base": theme-color("total"),
  "darken-1": darken(theme-color("total"), 5%),
  "darken-2": darken(theme-color("total"), 10%),
  "darken-3": darken(theme-color("total"), 15%),
  "darken-4": darken(theme-color("total"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #7B64FE
);

$available: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("available")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("available"), 30%),
  "lighten-5": lighten(theme-color("available"), 25%),
  "lighten-4": lighten(theme-color("available"), 20%),
  "lighten-3": lighten(theme-color("available"), 15%),
  "lighten-2": lighten(theme-color("available"), 10%),
  "lighten-1": lighten(theme-color("available"), 5%),
  "base": theme-color("available"),
  "darken-1": darken(theme-color("available"), 5%),
  "darken-2": darken(theme-color("available"), 10%),
  "darken-3": darken(theme-color("available"), 15%),
  "darken-4": darken(theme-color("available"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #0FD095
);

$occupied: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("occupied")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("occupied"), 30%),
  "lighten-5": lighten(theme-color("occupied"), 25%),
  "lighten-4": lighten(theme-color("occupied"), 20%),
  "lighten-3": lighten(theme-color("occupied"), 15%),
  "lighten-2": lighten(theme-color("occupied"), 10%),
  "lighten-1": lighten(theme-color("occupied"), 5%),
  "base": theme-color("occupied"),
  "darken-1": darken(theme-color("occupied"), 5%),
  "darken-2": darken(theme-color("occupied"), 10%),
  "darken-3": darken(theme-color("occupied"), 15%),
  "darken-4": darken(theme-color("occupied"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #FD6E5B
);

$pending: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("pending")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("pending"), 30%),
  "lighten-5": lighten(theme-color("pending"), 25%),
  "lighten-4": lighten(theme-color("pending"), 20%),
  "lighten-3": lighten(theme-color("pending"), 15%),
  "lighten-2": lighten(theme-color("pending"), 10%),
  "lighten-1": lighten(theme-color("pending"), 5%),
  "base": theme-color("pending"),
  "darken-1": darken(theme-color("pending"), 5%),
  "darken-2": darken(theme-color("pending"), 10%),
  "darken-3": darken(theme-color("pending"), 15%),
  "darken-4": darken(theme-color("pending"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #395A8B
);

$female: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("female")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("female"), 30%),
  "lighten-5": lighten(theme-color("female"), 25%),
  "lighten-4": lighten(theme-color("female"), 20%),
  "lighten-3": lighten(theme-color("female"), 15%),
  "lighten-2": lighten(theme-color("female"), 10%),
  "lighten-1": lighten(theme-color("female"), 5%),
  "base": theme-color("female"),
  "darken-1": darken(theme-color("female"), 5%),
  "darken-2": darken(theme-color("female"), 10%),
  "darken-3": darken(theme-color("female"), 15%),
  "darken-4": darken(theme-color("female"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #de678d
);

$male: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("male")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("male"), 30%),
  "lighten-5": lighten(theme-color("male"), 25%),
  "lighten-4": lighten(theme-color("male"), 20%),
  "lighten-3": lighten(theme-color("male"), 15%),
  "lighten-2": lighten(theme-color("male"), 10%),
  "lighten-1": lighten(theme-color("male"), 5%),
  "base": theme-color("male"),
  "darken-1": darken(theme-color("male"), 5%),
  "darken-2": darken(theme-color("male"), 10%),
  "darken-3": darken(theme-color("male"), 15%),
  "darken-4": darken(theme-color("male"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #265193
);

$reserved: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("reserved")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("reserved"), 30%),
  "lighten-5": lighten(theme-color("reserved"), 25%),
  "lighten-4": lighten(theme-color("reserved"), 20%),
  "lighten-3": lighten(theme-color("reserved"), 15%),
  "lighten-2": lighten(theme-color("reserved"), 10%),
  "lighten-1": lighten(theme-color("reserved"), 5%),
  "base": theme-color("reserved"),
  "darken-1": darken(theme-color("reserved"), 5%),
  "darken-2": darken(theme-color("reserved"), 10%),
  "darken-3": darken(theme-color("reserved"), 15%),
  "darken-4": darken(theme-color("reserved"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #D524FD
);

$expired: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("expired")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("expired"), 30%),
  "lighten-5": lighten(theme-color("expired"), 25%),
  "lighten-4": lighten(theme-color("expired"), 20%),
  "lighten-3": lighten(theme-color("expired"), 15%),
  "lighten-2": lighten(theme-color("expired"), 10%),
  "lighten-1": lighten(theme-color("expired"), 5%),
  "base": theme-color("expired"),
  "darken-1": darken(theme-color("expired"), 5%),
  "darken-2": darken(theme-color("expired"), 10%),
  "darken-3": darken(theme-color("expired"), 15%),
  "darken-4": darken(theme-color("expired"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #69127d
);

$doorOpen: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("doorOpen")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("doorOpen"), 30%),
  "lighten-5": lighten(theme-color("doorOpen"), 25%),
  "lighten-4": lighten(theme-color("doorOpen"), 20%),
  "lighten-3": lighten(theme-color("doorOpen"), 15%),
  "lighten-2": lighten(theme-color("doorOpen"), 10%),
  "lighten-1": lighten(theme-color("doorOpen"), 5%),
  "base": theme-color("doorOpen"),
  "darken-1": darken(theme-color("doorOpen"), 5%),
  "darken-2": darken(theme-color("doorOpen"), 10%),
  "darken-3": darken(theme-color("doorOpen"), 15%),
  "darken-4": darken(theme-color("doorOpen"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #0099FF
);

$alert: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("alert")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("alert"), 30%),
  "lighten-5": lighten(theme-color("alert"), 25%),
  "lighten-4": lighten(theme-color("alert"), 20%),
  "lighten-3": lighten(theme-color("alert"), 15%),
  "lighten-2": lighten(theme-color("alert"), 10%),
  "lighten-1": lighten(theme-color("alert"), 5%),
  "base": theme-color("alert"),
  "darken-1": darken(theme-color("alert"), 5%),
  "darken-2": darken(theme-color("alert"), 10%),
  "darken-3": darken(theme-color("alert"), 15%),
  "darken-4": darken(theme-color("alert"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #E30613
);

$offline: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("offline")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("offline"), 30%),
  "lighten-5": lighten(theme-color("offline"), 25%),
  "lighten-4": lighten(theme-color("offline"), 20%),
  "lighten-3": lighten(theme-color("offline"), 15%),
  "lighten-2": lighten(theme-color("offline"), 10%),
  "lighten-1": lighten(theme-color("offline"), 5%),
  "base": theme-color("offline"),
  "darken-1": darken(theme-color("offline"), 5%),
  "darken-2": darken(theme-color("offline"), 10%),
  "darken-3": darken(theme-color("offline"), 15%),
  "darken-4": darken(theme-color("offline"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #797979
);

$doorDisabled: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("doorDisabled")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("doorDisabled"), 30%),
  "lighten-5": lighten(theme-color("doorDisabled"), 25%),
  "lighten-4": lighten(theme-color("doorDisabled"), 20%),
  "lighten-3": lighten(theme-color("doorDisabled"), 15%),
  "lighten-2": lighten(theme-color("doorDisabled"), 10%),
  "lighten-1": lighten(theme-color("doorDisabled"), 5%),
  "base": theme-color("doorDisabled"),
  "darken-1": darken(theme-color("doorDisabled"), 5%),
  "darken-2": darken(theme-color("doorDisabled"), 10%),
  "darken-3": darken(theme-color("doorDisabled"), 15%),
  "darken-4": darken(theme-color("doorDisabled"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #232526
);

$easyToUse: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("easyToUse")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("easyToUse"), 30%),
  "lighten-5": lighten(theme-color("easyToUse"), 25%),
  "lighten-4": lighten(theme-color("easyToUse"), 20%),
  "lighten-3": lighten(theme-color("easyToUse"), 15%),
  "lighten-2": lighten(theme-color("easyToUse"), 10%),
  "lighten-1": lighten(theme-color("easyToUse"), 5%),
  "base": theme-color("easyToUse"),
  "darken-1": darken(theme-color("easyToUse"), 5%),
  "darken-2": darken(theme-color("easyToUse"), 10%),
  "darken-3": darken(theme-color("easyToUse"), 15%),
  "darken-4": darken(theme-color("easyToUse"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #03CFE7
);

$safeTime: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("safeTime")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("safeTime"), 30%),
  "lighten-5": lighten(theme-color("safeTime"), 25%),
  "lighten-4": lighten(theme-color("safeTime"), 20%),
  "lighten-3": lighten(theme-color("safeTime"), 15%),
  "lighten-2": lighten(theme-color("safeTime"), 10%),
  "lighten-1": lighten(theme-color("safeTime"), 5%),
  "base": theme-color("safeTime"),
  "darken-1": darken(theme-color("safeTime"), 5%),
  "darken-2": darken(theme-color("safeTime"), 10%),
  "darken-3": darken(theme-color("safeTime"), 15%),
  "darken-4": darken(theme-color("safeTime"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #43875C
);

$ocher: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("ocher")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("ocher"), 30%),
  "lighten-5": lighten(theme-color("ocher"), 25%),
  "lighten-4": lighten(theme-color("ocher"), 20%),
  "lighten-3": lighten(theme-color("ocher"), 15%),
  "lighten-2": lighten(theme-color("ocher"), 10%),
  "lighten-1": lighten(theme-color("ocher"), 5%),
  "base": theme-color("ocher"),
  "darken-1": darken(theme-color("ocher"), 5%),
  "darken-2": darken(theme-color("ocher"), 10%),
  "darken-3": darken(theme-color("ocher"), 15%),
  "darken-4": darken(theme-color("ocher"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #FAC88C
);

$greent: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("greent")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("greent"), 30%),
  "lighten-5": lighten(theme-color("greent"), 25%),
  "lighten-4": lighten(theme-color("greent"), 20%),
  "lighten-3": lighten(theme-color("greent"), 15%),
  "lighten-2": lighten(theme-color("greent"), 10%),
  "lighten-1": lighten(theme-color("greent"), 5%),
  "base": theme-color("greent"),
  "darken-1": darken(theme-color("greent"), 5%),
  "darken-2": darken(theme-color("greent"), 10%),
  "darken-3": darken(theme-color("greent"), 15%),
  "darken-4": darken(theme-color("greent"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #2D3C3C
);

$biziq: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("biziq")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("biziq"), 30%),
  "lighten-5": lighten(theme-color("biziq"), 25%),
  "lighten-4": lighten(theme-color("biziq"), 20%),
  "lighten-3": lighten(theme-color("biziq"), 15%),
  "lighten-2": lighten(theme-color("biziq"), 10%),
  "lighten-1": lighten(theme-color("biziq"), 5%),
  "base": theme-color("biziq"),
  "darken-1": darken(theme-color("biziq"), 5%),
  "darken-2": darken(theme-color("biziq"), 10%),
  "darken-3": darken(theme-color("biziq"), 15%),
  "darken-4": darken(theme-color("biziq"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #4600A2
);

$eupago: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("eupago")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("eupago"), 30%),
  "lighten-5": lighten(theme-color("eupago"), 25%),
  "lighten-4": lighten(theme-color("eupago"), 20%),
  "lighten-3": lighten(theme-color("eupago"), 15%),
  "lighten-2": lighten(theme-color("eupago"), 10%),
  "lighten-1": lighten(theme-color("eupago"), 5%),
  "base": theme-color("eupago"),
  "darken-1": darken(theme-color("eupago"), 5%),
  "darken-2": darken(theme-color("eupago"), 10%),
  "darken-3": darken(theme-color("eupago"), 15%),
  "darken-4": darken(theme-color("eupago"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #031E1B
);

$lokk: (
  "gradient-light":
    lighten(saturate(adjust-hue((theme-color("lokk")), 25), 11.9), 13.33),
  "light-badge": lighten(theme-color("lokk"), 30%),
  "lighten-5": lighten(theme-color("lokk"), 25%),
  "lighten-4": lighten(theme-color("lokk"), 20%),
  "lighten-3": lighten(theme-color("lokk"), 15%),
  "lighten-2": lighten(theme-color("lokk"), 10%),
  "lighten-1": lighten(theme-color("lokk"), 5%),
  "base": theme-color("lokk"),
  "darken-1": darken(theme-color("lokk"), 5%),
  "darken-2": darken(theme-color("lokk"), 10%),
  "darken-3": darken(theme-color("lokk"), 15%),
  "darken-4": darken(theme-color("lokk"), 20%),
  "accent-1": #ffeef1,
  "accent-2": #ffd6db,
  "accent-3": #ffecee,
  "accent-4": #ffd3d7,
  "gradient-bg": #02C3AB
);

$colors: (
  "white":        $white,
  "black":        $black,
  "dark":         $dark,
  "light":        $light,
  "primary":      $primary,
  "secondary":    $secondary,
  "success":      $success,
  "info":         $info,
  "warning":      $warning,
  "danger":       $danger,
  "clean":        $clean,
  "clean-light":  $clean-light,
  "total":        $total,
  "available":    $available,
  "occupied":     $occupied,
  "pending":      $pending,
  "female":       $female,
  "male":         $male,
  "reserved":     $reserved,
  "doorOpen":     $doorOpen,
  "alert":        $alert,
  "offline":      $offline,
  "doorDisabled": $doorDisabled,
  "easyToUse":    $easyToUse,
  "safeTime":     $safeTime,
  "expired":      $expired,
  "ocher":        $ocher,
  "greent":       $greent,
  "biziq":        $biziq,
  "eupago":       $eupago,
  "lokk":         $lokk,
);